import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import RichText from '@components/RichText/RichText';
import type { Fields } from '@sitecore/types/GenericFormInputField';
import { InputTelephone } from '@sparky';

import { FormValues, GenericFormFC, GenericFormInputProps } from '../../util';

const NAME_FALLBACK = 'phoneNumberFormField';
type CountryCode = 'NL' | 'BE' | 'FR' | 'LU';

function isGenericFormPhoneNumber(field: Fields) {
  return field?.genericInputField !== undefined;
}

const GenericFormPhoneNumberInputField: GenericFormFC<GenericFormInputProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormPhoneNumber(fields)) {
    return null;
  }

  const { hint, label, placeholder } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  return (
    <InputTelephone
      autoComplete="tel"
      error={errors?.[name]?.message}
      hint={<RichText html={hint}></RichText>}
      label={label}
      placeholder={placeholder}
      {...register(name)}
    />
  );
};

GenericFormPhoneNumberInputField.yupValidationScheme = (fields: Fields) => {
  const { requiredMessage, validationMessage } = fields?.genericInputField?.value ?? {};
  const name = fields?.genericInputField?.value?.name || NAME_FALLBACK;

  const schema = yup.string().test('phoneNumberValid', validationMessage, value => {
    const validBE = isValidPhoneNumber(value || '', 'BE');
    const validNL = isValidPhoneNumber(value || '', 'NL');
    const validFr = isValidPhoneNumber(value || '', 'FR');
    const validLU = isValidPhoneNumber(value || '', 'LU');
    return validBE || validNL || validFr || validLU;
  });

  return {
    [name]: requiredMessage ? schema.required(requiredMessage) : schema,
  };
};

function isValidPhoneNumber(phoneNumber: string, countryCode: CountryCode): boolean {
  const normalizedNumber = phoneNumber.replace(/[\s\-]/g, '');

  const regexPatterns: { [key in CountryCode]: RegExp } = {
    NL: /^(\+31|0)(\s?|-?\.?)([1-9]{1}[0-9]{1,2})(\s?|-?\.?)?([0-9]{3})(\s?|-?\.?)?([0-9]{4})$/,
    BE: /^(\+32|0)(\s?|-?\.?)([1-9]{1}[0-9]{1,3})(\s?|-?\.?)?([0-9]{3})(\s?|-?\.?)?([0-9]{3,4})$/,
    FR: /^(\+33|0)(\s?|-?\.?)([1-9]{1})(\s?|-?\.?)?([0-9]{2})(\s?|-?\.?)?([0-9]{2})(\s?|-?\.?)?([0-9]{2})(\s?|-?\.?)?([0-9]{2})$/,
    LU: /^(\+352|0?)(\s?|-?\.?)([1-9]{1}[0-9]{1,3})(\s?|-?\.?)?([0-9]{2,4})(\s?|-?\.?)?([0-9]{2,4})$/,
  };

  const regex = regexPatterns[countryCode];

  return regex.test(normalizedNumber);
}

export default GenericFormPhoneNumberInputField;
