import { Divider } from '@sparky';

import { GenericFormFC } from '../../util';

const GenericFormDivider: GenericFormFC = () => {
  // TODO: emphasis and weight should probably be configurable at some point
  return <Divider emphasis="low" weight="regular" />;
};

export default GenericFormDivider;
