import { Controller, useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import RichText from '@components/RichText/RichText';
import type { Fields } from '@sitecore/types/GenericFormSalutationField';
import { RadioButton, RadioGroup } from '@sparky';

import { FormValues, GenericFormFC, GenericFormSalutationProps } from '../../util';

const NAME_FALLBACK = 'salutationFormField';

function isGenericFormSalutation(field: Fields) {
  return field?.name !== undefined;
}

const GenericFormSalutationField: GenericFormFC<GenericFormSalutationProps> = ({ fields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormSalutation(fields)) {
    return null;
  }

  const name = fields?.name?.value || NAME_FALLBACK;
  const { hint, label, options = [] } = fields?.salutationFormField?.value ?? {};

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, name } }) => {
        const currentValue = typeof value === 'string' ? value : '';

        return (
          <RadioGroup
            name={name}
            label={label}
            error={errors?.[name]?.message}
            hint={<RichText html={hint}></RichText>}
            value={currentValue}
            onValueChange={onChange}>
            {options?.map(option => (
              <RadioButton value={option.value} key={option.value}>
                {option.label}
              </RadioButton>
            ))}
          </RadioGroup>
        );
      }}
    />
  );
};

GenericFormSalutationField.yupValidationScheme = (fields: Fields) => {
  const name = fields?.name?.value || NAME_FALLBACK;
  const { requiredMessage } = fields?.salutationFormField?.value ?? {};

  return { [name]: requiredMessage ? yup.string().required(requiredMessage) : yup.string() };
};

export default GenericFormSalutationField;
