import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { isValid } from '@common/date';
import type { Fields } from '@sitecore/types/GenericFormDateField';
import { InputDate } from '@sparky';

import { FormValues, GenericFormDateProps, GenericFormFC } from '../../util';

const NAME_FALLBACK = 'dateFormField';
const DAY_FALLBACK = 'dayFormField';
const MONTH_FALLBACK = 'monthFormField';
const YEAR_FALLBACK = 'yearFormField';

function isGenericFormDate(field: Fields) {
  return field?.dateFormField !== undefined;
}

const GenericFormDateField: GenericFormFC<GenericFormDateProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormDate(fields)) {
    return null;
  }

  const { label } = fields?.dateFormField?.value ?? {};
  const name = fields?.dateFormField?.value?.name || NAME_FALLBACK;

  const { label: dayLabel, placeholder: dayPlaceholder } = fields?.dayFormField?.value ?? {};
  const dayName = fields?.dayFormField?.value?.name || DAY_FALLBACK;
  const { label: monthLabel, placeholder: monthPlaceholder } = fields?.monthFormField?.value ?? {};
  const monthName = fields?.monthFormField?.value?.name || MONTH_FALLBACK;
  const { label: yearLabel, placeholder: yearPlaceholder } = fields?.yearFormField?.value ?? {};
  const yearName = fields?.yearFormField?.value?.name || YEAR_FALLBACK;

  return (
    <InputDate
      error={
        errors?.[name]?.message ??
        errors?.[dayName]?.message ??
        errors?.[monthName]?.message ??
        errors?.[yearName]?.message
      }
      label={label}
      name={name}>
      <InputDate.Day
        hasError={!!errors?.[dayName]?.message}
        label={dayLabel}
        placeholder={dayPlaceholder}
        {...register(dayName)}
      />
      <InputDate.Month
        hasError={!!errors?.[monthName]?.message}
        label={monthLabel}
        placeholder={monthPlaceholder}
        {...register(monthName)}
      />
      <InputDate.Year
        hasError={!!errors?.[yearName]?.message}
        label={yearLabel}
        placeholder={yearPlaceholder}
        {...register(yearName)}
      />
    </InputDate>
  );
};

GenericFormDateField.yupValidationScheme = (fields: Fields) => {
  const { requiredMessage } = fields?.dateFormField?.value ?? {};
  const name = fields?.dateFormField?.value?.name || NAME_FALLBACK;

  const { requiredMessage: dayRequiredMessage } = fields?.dayFormField?.value ?? {};
  const dayName = fields?.dayFormField?.value?.name || DAY_FALLBACK;

  const { requiredMessage: monthRequiredMessage } = fields?.monthFormField?.value ?? {};
  const monthName = fields?.monthFormField?.value?.name || MONTH_FALLBACK;

  const { requiredMessage: yearRequiredMessage } = fields?.yearFormField?.value ?? {};
  const yearName = fields?.yearFormField?.value?.name || YEAR_FALLBACK;

  return {
    [dayName]: dayRequiredMessage
      ? yup.number().typeError(dayRequiredMessage).min(1, dayRequiredMessage).max(31, dayRequiredMessage)
      : undefined,
    [monthName]: monthRequiredMessage
      ? yup.number().typeError(monthRequiredMessage).min(1, monthRequiredMessage).max(12, monthRequiredMessage)
      : undefined,
    [yearName]: yearRequiredMessage
      ? yup.number().typeError(yearRequiredMessage).min(1900, yearRequiredMessage)
      : undefined,
    [name]: yup.object({}).test('valid date', fields?.dateFormField?.value?.requiredMessage, (_, context) => {
      const {
        parent: { [dayName]: dayValue, [monthName]: monthValue, [yearName]: yearValue },
      } = context;

      if (!requiredMessage && !dayValue && !monthValue && !yearValue) {
        return true;
      }

      return isValid(yearValue, monthValue, dayValue);
    }),
  };
};

export default GenericFormDateField;
