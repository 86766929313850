import { useEffect, useState } from 'react';

import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import RichText from '@components/RichText/RichText';
import type { Fields } from '@sitecore/types/GenericFormCheckboxWithValueField';
import { Checkbox } from '@sparky';

import { FormValues, GenericFormCheckboxWithValueProps, GenericFormFC } from '../../util';

const NAME_FALLBACK = 'checkboxFormField';
const VALUE_FALLBACK = 'true';

function isGenericFormCheckbox(field: Fields) {
  return field?.checkboxFormField !== undefined;
}

const GenericFormCheckboxWithValueField: GenericFormFC<GenericFormCheckboxWithValueProps> = ({ fields }) => {
  const [checked, setChecked] = useState(false);
  const { register, unregister } = useFormContext();
  const { errors } = useFormState<FormValues>();

  const { hint, label } = fields?.checkboxFormField?.value ?? {};
  const name = fields?.checkboxFormField?.value?.name || NAME_FALLBACK;
  const value = fields?.checkboxValueText?.value || VALUE_FALLBACK;

  useEffect(() => {
    if (!checked) {
      // Make sure the value get to the form submission only when checked
      unregister(name);
    }
  }, [unregister, checked, name]);

  if (!isGenericFormCheckbox(fields)) {
    return null;
  }

  return (
    <>
      {checked && <input type="hidden" value={value} {...register(name)} />}
      <Checkbox
        error={errors?.[name]?.message}
        hint={<RichText html={hint}></RichText>}
        label={label}
        onChange={e => setChecked(e.target.checked)}
        name="checkbox"
      />
    </>
  );
};

GenericFormCheckboxWithValueField.yupValidationScheme = (fields: Fields) => {
  const { requiredMessage } = fields?.checkboxFormField?.value ?? {};
  const name = fields?.checkboxFormField?.value?.name || NAME_FALLBACK;

  return {
    [name]: requiredMessage
      ? yup.string().test(fields?.checkboxValueText?.value, requiredMessage, value => !!value)
      : yup.string(),
  };
};

export default GenericFormCheckboxWithValueField;
