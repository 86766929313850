import { Controller, useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import RichText from '@components/RichText/RichText';
import { GenericFormCheckboxGroupInterface } from '@sitecore/types/manual/GenericFormCheckboxGroup';
import { CheckTile, CheckboxGroup } from '@sparky';

import { FormValues, GenericFormCheckboxGroupProps, GenericFormFC } from '../../util';

const NAME_FALLBACK = 'checkboxGroupFormField';

function isGenericFormCheckboxGroup(field: GenericFormCheckboxGroupInterface) {
  return field?.checkboxGroupFormField !== undefined;
}

const GenericFormCheckboxGroup: GenericFormFC<GenericFormCheckboxGroupProps> = ({ fields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormCheckboxGroup(fields)) {
    return null;
  }

  const checkboxChange = (name: string, value: Array<string> = [], onChange: (...event: unknown[]) => void) => {
    const currentValue = Array.isArray(value) ? value : [];

    if (currentValue.includes(name)) {
      onChange(currentValue.filter(val => val !== name));
    } else {
      onChange([...currentValue, name]);
    }
  };

  const { items = [] } = fields;
  const name = fields?.name?.value || NAME_FALLBACK;
  const { hint, label } = fields?.checkboxGroupFormField?.value ?? {};

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => {
        const currentValue = Array.isArray(value) ? value : [];

        return (
          <CheckboxGroup label={label} error={errors?.[name]?.message} hint={<RichText html={hint}></RichText>}>
            {items?.map(item => (
              <CheckTile
                name={item.displayName}
                key={item.id}
                isChecked={currentValue?.includes(item.displayName)}
                onChange={() => checkboxChange(item.displayName, currentValue, onChange)}>
                {item.displayName}
              </CheckTile>
            ))}
          </CheckboxGroup>
        );
      }}
    />
  );
};

GenericFormCheckboxGroup.yupValidationScheme = (fields: GenericFormCheckboxGroupInterface) => {
  const name = fields?.name?.value || NAME_FALLBACK;
  const { requiredMessage, validationMessage } = fields?.checkboxGroupFormField?.value ?? {};
  const minChecks = Number(fields.minimumRequiredChecksNumber.value);

  if (minChecks === 0 || !requiredMessage) return;

  return {
    [name]: yup.array().of(yup.string()).required(requiredMessage).min(minChecks, validationMessage),
  };
};

export default GenericFormCheckboxGroup;
