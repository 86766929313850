import { Controller, useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import RichText from '@components/RichText/RichText';
import { GenericFormInputSelectInterface } from '@sitecore/types/manual/GenericFormInputSelect';
import { InputSelect } from '@sparky';

import { FormValues, GenericFormFC, GenericFormInputSelectProps } from '../../util';

const NAME_FALLBACK = 'selectFormField';

function isGenericInputSelect(field: GenericFormInputSelectInterface) {
  return field?.selectListFormField !== undefined;
}

const GenericFormInputSelect: GenericFormFC<GenericFormInputSelectProps> = ({ fields }) => {
  const { control } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericInputSelect(fields)) {
    return null;
  }

  const name = fields?.name?.value || NAME_FALLBACK;
  const { hint, label, placeholder } = fields?.selectListFormField?.value ?? {};

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, onChange } }) => (
        <InputSelect
          error={errors?.[name]?.message}
          hint={<RichText html={hint}></RichText>}
          label={label}
          name={name}
          onChange={onChange}
          options={fields.items?.map(item => ({
            label: item.fields.label.value || item.displayName,
            value: item.name.replace(/\s+/g, '-'),
          }))}
          placeholder={placeholder}
        />
      )}
    />
  );
};

GenericFormInputSelect.yupValidationScheme = (fields: GenericFormInputSelectInterface) => {
  const name = fields?.name?.value || NAME_FALLBACK;
  const { requiredMessage } = fields?.selectListFormField?.value ?? {};

  return { [name]: requiredMessage ? yup.string().required(requiredMessage) : yup.string() };
};

export default GenericFormInputSelect;
