import { Controller, useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { maskHouseNumber } from '@common/maskInput/maskHouseNumber';
import { maskPostalcode } from '@common/maskInput/maskPostalcode';
import postalCodeRegex from '@common/validation/postalCodeRegex';
import RichText from '@components/RichText/RichText';
import type { Fields } from '@sitecore/types/GenericFormAddressField';
import { InputText, Grid } from '@sparky';

import { FormValues, GenericFormAddressProps, GenericFormFC } from '../../util';

// Important: these names map to the field names expected by DC, so they should not just be changed
const POSTALCODE_FALLBACK = 'postalCode';
const HOUSENUMBER_FALLBACK = 'houseNumber';
const HOUSENUMBERSUFFIX_FALLBACK = 'houseNumberSuffix';

function isGenericFormAddress(field: Fields) {
  return field?.houseNumberFormField !== undefined;
}

const GenericFormAddressField: GenericFormFC<GenericFormAddressProps> = ({ fields }) => {
  const { register, control } = useFormContext();
  const { errors } = useFormState<FormValues>();

  if (!isGenericFormAddress(fields)) {
    return null;
  }

  const {
    hint: postalCodeHint,
    label: postalCodeLabel,
    placeholder: postalCodePlaceholder,
  } = fields?.postalCodeFormField?.value ?? {};
  const postalCodeName = fields?.postalCodeFormField?.value?.name || POSTALCODE_FALLBACK;

  const {
    hint: houseNumberHint,
    label: houseNumberLabel,
    placeholder: houseNumberPlaceholder,
  } = fields?.houseNumberFormField?.value ?? {};
  const houseNumberName = fields?.houseNumberFormField?.value?.name || HOUSENUMBER_FALLBACK;

  const {
    hint: houseNumberSuffixHint,
    label: houseNumberSuffixLabel,
    placeholder: houseNumberSuffixPlaceholder,
  } = fields?.houseNumberSuffixFormField?.value ?? {};
  const houseNumberSuffixName = fields?.houseNumberSuffixFormField?.value?.name || HOUSENUMBERSUFFIX_FALLBACK;

  return (
    <Grid columns={{ initial: '1', md: '2', lg: '3' }} gap="6">
      <Controller
        control={control}
        name={postalCodeName}
        rules={{
          required: fields.postalCodeFormField?.value?.requiredMessage,
        }}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <InputText
            error={errors?.[postalCodeName]?.message}
            hint={<RichText html={postalCodeHint}></RichText>}
            label={postalCodeLabel}
            placeholder={postalCodePlaceholder}
            onChange={event => {
              onChange(maskPostalcode(event));
            }}
            value={value}
            onBlur={onBlur}
            name={name}
          />
        )}
      />
      <Controller
        control={control}
        name={houseNumberName}
        render={({ field: { name, value, onChange, onBlur } }) => (
          <InputText
            error={errors?.[houseNumberName]?.message}
            hint={<RichText html={houseNumberHint}></RichText>}
            label={houseNumberLabel}
            placeholder={houseNumberPlaceholder}
            value={value}
            onBlur={onBlur}
            name={name}
            onChange={event => {
              onChange(maskHouseNumber(event));
            }}
          />
        )}
      />
      <InputText
        error={errors?.[houseNumberSuffixName]?.message}
        hint={<RichText html={houseNumberSuffixHint}></RichText>}
        label={houseNumberSuffixLabel}
        placeholder={houseNumberSuffixPlaceholder}
        {...register(houseNumberSuffixName)}
      />
    </Grid>
  );
};

interface PostalCodeFieldParams {
  requiredMessage?: string;
  validationMessage?: string;
}

function getPostalCodeField({ requiredMessage, validationMessage }: PostalCodeFieldParams = {}) {
  let postalCodeFormField = yup.string();

  if (requiredMessage) {
    postalCodeFormField = postalCodeFormField.required(requiredMessage);
  }

  if (validationMessage) {
    postalCodeFormField = postalCodeFormField.matches(postalCodeRegex, validationMessage);
  }

  return postalCodeFormField;
}

GenericFormAddressField.yupValidationScheme = (fields: Fields) => {
  const postalCodeFields = fields?.postalCodeFormField?.value || {};
  const postalCodeName = postalCodeFields.name || POSTALCODE_FALLBACK;

  const { requiredMessage: houseNumberRequiredMessage } = fields?.houseNumberFormField?.value ?? {};
  const houseNumberName = fields?.houseNumberFormField?.value?.name || HOUSENUMBER_FALLBACK;

  const { requiredMessage: houseNumberSuffixRequiredMessage } = fields?.houseNumberSuffixFormField?.value ?? {};
  const houseNumberSuffixName = fields?.houseNumberSuffixFormField?.value?.name || HOUSENUMBERSUFFIX_FALLBACK;

  return {
    [postalCodeName]: getPostalCodeField(postalCodeFields),
    [houseNumberName]: houseNumberRequiredMessage ? yup.string().required(houseNumberRequiredMessage) : yup.string(),
    [houseNumberSuffixName]: houseNumberSuffixRequiredMessage
      ? yup.string().required(houseNumberSuffixRequiredMessage)
      : yup.string(),
  };
};

export default GenericFormAddressField;
