import { object, string } from 'yup';
import type { Schema } from 'yup';

import { JssFormRendering } from '@sitecore/types/manual/GenericForm';

import * as genericFormComponents from '../Components';
export interface GenericFormFields {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: Schema<any>;
}

export const genericFormSchema = (placeholders: JssFormRendering[]) => {
  const schemaFields: GenericFormFields = {
    // recaptchaToken is required for all forms
    recaptchaToken: string().required(),
  };

  placeholders.forEach(placeholder => {
    // eslint-disable-next-line import/namespace
    const component = genericFormComponents[placeholder.componentName as keyof typeof genericFormComponents];

    if (!component || !component.yupValidationScheme) return;

    // @ts-ignore Property 'fields' does not exist on type - this is only ran for components that have yupValidationScheme (and thus fields)
    Object.assign(schemaFields, component.yupValidationScheme(placeholder.fields));
  });

  return object().shape<GenericFormFields>(schemaFields);
};
