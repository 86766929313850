import { useFormContext, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import type { Fields } from '@sitecore/types/GenericFormBeAddressField';
import { InputText, Grid } from '@sparky';

import { FormValues, GenericFormBeAddressProps, GenericFormFC } from '../../util';

// Important: these names map to the field names expected by DC, so they should not just be changed
const POSTALCODE_FALLBACK = 'postalCode';
const CITY_FALLBACK = 'city';
const HOUSENUMBER_FALLBACK = 'houseNumber';
const BUSNUMBER_FALLBACK = 'busNumber';
const STREETNAME_FALLBACK = 'streetName';

const GenericFormBeAddressField: GenericFormFC<GenericFormBeAddressProps> = ({ fields }) => {
  const { register } = useFormContext();
  const { errors } = useFormState<FormValues>();

  const {
    hint: postalCodeHint,
    label: postalCodeLabel,
    placeholder: postalCodePlaceholder,
  } = fields?.postalCodeFormField?.value ?? {};
  const postalCodeName = fields?.postalCodeFormField?.value?.name || POSTALCODE_FALLBACK;

  const { hint: cityHint, label: cityLabel, placeholder: cityPlaceholder } = fields?.cityFormField?.value ?? {};
  const cityName = fields?.cityFormField?.value?.name || CITY_FALLBACK;

  const {
    hint: houseNumberHint,
    label: houseNumberLabel,
    placeholder: houseNumberPlaceholder,
  } = fields?.houseNumberFormField?.value ?? {};
  const houseNumberName = fields?.houseNumberFormField?.value?.name || HOUSENUMBER_FALLBACK;

  const {
    hint: busNumberHint,
    label: busNumberLabel,
    placeholder: busNumberPlaceholder,
  } = fields?.busNumberFormField?.value ?? {};
  const busNumberName = fields?.busNumberFormField?.value?.name || BUSNUMBER_FALLBACK;

  const {
    hint: streetNameHint,
    label: streetNameLabel,
    placeholder: streetNamePlaceholder,
  } = fields?.streetNameFormField?.value ?? {};
  const streetNameName = fields?.streetNameFormField?.value?.name || STREETNAME_FALLBACK;

  return (
    <Grid columns={{ initial: '1', md: '2', lg: '3' }} gap="6">
      <InputText
        error={errors?.[postalCodeName]?.message}
        hint={postalCodeHint}
        label={postalCodeLabel}
        placeholder={postalCodePlaceholder}
        {...register(postalCodeName)}
      />
      <InputText
        error={errors?.[cityName]?.message}
        hint={cityHint}
        label={cityLabel}
        placeholder={cityPlaceholder}
        {...register(cityName)}
      />
      <InputText
        error={errors?.[streetNameName]?.message}
        hint={streetNameHint}
        label={streetNameLabel}
        placeholder={streetNamePlaceholder}
        {...register(streetNameName)}
      />
      <InputText
        error={errors?.[houseNumberName]?.message}
        hint={houseNumberHint}
        label={houseNumberLabel}
        placeholder={houseNumberPlaceholder}
        {...register(houseNumberName)}
      />
      <InputText
        error={errors?.[busNumberName]?.message}
        hint={busNumberHint}
        label={busNumberLabel}
        placeholder={busNumberPlaceholder}
        {...register(busNumberName)}
      />
    </Grid>
  );
};

GenericFormBeAddressField.yupValidationScheme = (fields: Fields) => {
  const { requiredMessage: postalCodeRequiredMessage } = fields?.postalCodeFormField?.value ?? {};
  const postalCodeName = fields?.postalCodeFormField?.value?.name || POSTALCODE_FALLBACK;

  const { requiredMessage: cityRequiredMessage } = fields?.cityFormField?.value ?? {};
  const cityName = fields?.cityFormField?.value?.name || CITY_FALLBACK;

  const { requiredMessage: houseNumberRequiredMessage } = fields?.houseNumberFormField?.value ?? {};
  const houseNumberName = fields?.houseNumberFormField?.value?.name || HOUSENUMBER_FALLBACK;

  const { requiredMessage: streetNameRequiredMessage } = fields?.streetNameFormField?.value ?? {};
  const streetNameName = fields?.streetNameFormField?.value?.name || STREETNAME_FALLBACK;

  return {
    [postalCodeName]: yup.string().required(postalCodeRequiredMessage),
    [streetNameName]: yup.string().required(streetNameRequiredMessage),
    [houseNumberName]: yup.string().required(houseNumberRequiredMessage),
    [cityName]: yup.string().required(cityRequiredMessage),
  };
};

export default GenericFormBeAddressField;
